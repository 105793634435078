import * as React from 'react';
import {useTranslation} from "react-i18next";
import {Alert, CircularProgress} from "@mui/material";
import {LoadingItem} from "../loading-item/LoadingItem";
import PlannedExpenseWidget from "../planned-expense-widget/PlannedExpenseWidget";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import PlannedExpenseRequestDto from "../../dto/PlannedExpenseRequestDto";
import PlannedExpenseCreateDialog from "../planned-expense-create-dialog/PlannedExpenseCreateDialog";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useCreatePlannedExpenseMutation} from "../../store";

interface Props {
    budgetBusinessKey: string,
    dateFrom: string,
    dateTo: string
    expenseCategories: ExpenseCategoryDto[]
    onClickContinue: () => void
    onClickBack: () => void
    summariesValid: () => boolean
}

export default function PlannedExpensePlanning(props: Props) {
    const {t} = useTranslation();
    const {
        budgetBusinessKey,
        dateFrom,
        dateTo,
        expenseCategories,
        onClickBack,
        onClickContinue,
        summariesValid
    } = props;
    const [
        createPlannedExpense,
        {
            isLoading: isCreatePlannedExpensesLoading,
            error: createPlannedExpenseError
        }] = useCreatePlannedExpenseMutation();

    const onSave = async (data: PlannedExpenseRequestDto) => {
        await createPlannedExpense(data);
    };

    if (isCreatePlannedExpensesLoading) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }

    if (createPlannedExpenseError) {
        return <Alert severity="error">{t("Cannot create planned expense!")}</Alert>
    }

    return (
        <div>
            <div>
                <PlannedExpenseCreateDialog
                    budgetBusinessKey={budgetBusinessKey}
                    expenseCategories={expenseCategories}
                    onSave={onSave}
                    dateFrom={dateFrom}
                    disabled={!summariesValid()}
                />
            </div>
            <div>
                {!summariesValid() ? <Alert severity="error">
                    {t('The total planned expenses exceed the sum of revenues!')}
                </Alert> : null}
            </div>
            <div>
                <Box sx={{mb: 2}}>
                    <div>
                        <Button
                            disabled={false}
                            onClick={onClickBack}
                            sx={{mt: 1, mr: 1}}
                        >
                            {t('Back')}
                        </Button>
                        <Button
                            variant="contained"
                            disabled={!summariesValid()}
                            onClick={onClickContinue}
                            sx={{mt: 1, mr: 1}}
                        >
                            {t('Complete')}
                        </Button>
                    </div>
                </Box>
            </div>
            <div>
                <h2>{t('Planned expenses')}</h2>
                <PlannedExpenseWidget
                    budgetBusinessKey={budgetBusinessKey}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    expenseCategories={expenseCategories}
                />
            </div>
        </div>
    );
}