import * as React from 'react';
import {useEffect, useState} from 'react';
import RealExpenseRequestDto from "../../dto/RealExpenseRequestDto";
import RealExpenseForm from "../../components/real-expense-form/RealExpenseForm";
import PlannedExpenseDropdownDto from "../../dto/PlannedExpenseDropdownDto";
import {useLocation, useNavigate} from "react-router-dom";
import {Alert, Breadcrumbs, CircularProgress, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import {useTranslation} from "react-i18next";
import ContentItem from "../../components/content-item/ContentItem";
import {useSelector} from "react-redux";
import {
    RootState,
    useCreateRealExpenseMutation,
    useFetchExpenseCategoriesQuery,
    useFetchPlannedExpensesDropdownQuery
} from "../../store";
import {LoadingItem} from "../../components/loading-item/LoadingItem";

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function RealExpenseCreate() {
    const {t} = useTranslation();
    const query = useQuery();
    const selectedBudget = useSelector((state: RootState) => state.selectedBudget.selectedBudget);
    const {data: expenseCategories} = useFetchExpenseCategoriesQuery(selectedBudget, {
        skip: !selectedBudget
    });
    const {dateFrom, dateTo} = useSelector((state: RootState) => state.filter);
    const [initialPlannedExpense, setInitialPlannedExpense] = useState<PlannedExpenseDropdownDto | null>(null);
    const plannedExpenseBusinessKey: string | null = query.get("plannedExpenseId") !== null
        ? query.get("plannedExpenseId")
        : null;
    const navigate = useNavigate();
    const [createRealExpense, {isLoading, error}] = useCreateRealExpenseMutation();
    const {
        data: plannedExpenses,
        isLoading: isPlannedExpensesLoading,
        error: plannedExpensesError
    } = useFetchPlannedExpensesDropdownQuery({
        budgetBusinessKey: selectedBudget,
        dateFrom,
        dateTo
    });

    const onSaveRealExpense = async (data: RealExpenseRequestDto) => {
        await createRealExpense(data);
        if (plannedExpenseBusinessKey !== null) {
            navigate("/planning");
            return;
        }
        navigate("/");
    };

    const findPlannedExpense = (results: PlannedExpenseDropdownDto[], businessKey: string) => {
        const result = results.find(item => item.businessKey === businessKey);
        if (result === undefined) {
            return null;
        }

        return result;
    };

    useEffect(() => {
        if (!plannedExpenses) {
            return;
        }

        if (plannedExpenseBusinessKey !== null) {
            setInitialPlannedExpense(findPlannedExpense(plannedExpenses, plannedExpenseBusinessKey));
        }
    }, [plannedExpenses]);


    if ((isLoading || isPlannedExpensesLoading)) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }

    if (error) {
        return <Alert severity="error">{t("Cannot create expense!")}</Alert>;
    }

    if (plannedExpensesError || !plannedExpenses) {
        return <Alert severity="error">{t("Cannot load planned expenses!")}</Alert>;
    }

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <ContentItem>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" href="/">
                                Home
                            </Link>
                            <Typography color="text.primary">{t('Create expense')}</Typography>
                        </Breadcrumbs>
                        <h2>{t('Create expense')}</h2>
                        <RealExpenseForm
                            budgetBusinessKey={selectedBudget}
                            onSaveRealExpense={onSaveRealExpense}
                            plannedExpenses={plannedExpenses}
                            initialPlannedExpense={initialPlannedExpense}
                            expenseCategories={expenseCategories}
                        />
                    </ContentItem>
                </Grid>
            </Grid>
        </div>
    );
}

export default RealExpenseCreate;