import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import baseUrl from "../../api/baseUrl";
import {RootState} from "../index";
import RealExpensesSearchRequest from "../../interfaces/RealExpensesSearchRequest";
import RealExpenseUpdateRequestDto from "../../dto/RealExpenseUpdateRequestDto";
import RealExpenseRequestDto from "../../dto/RealExpenseRequestDto";
import RealExpensesSummarySearchRequest from "../../interfaces/RealExpensesSummarySearchRequest";
import PageableDto from "../../dto/PageableDto";
import RealExpenseDto from "../../dto/RealExpenseDto";

const realExpenseApi = createApi({
    reducerPath: "realExpense",
    tagTypes: ['RealExpenses'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}/api/real-expense`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.token;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        createRealExpense: builder.mutation({
            query: (body: RealExpenseRequestDto) => { // todo: clean up interfaces dtos and interfaces folder to one place
                return {
                    url: '',
                    method: 'POST',
                    body: body
                }
            },
            invalidatesTags: ['RealExpenses'],
        }),
        updateRealExpense: builder.mutation({
            query: (body: RealExpenseUpdateRequestDto) => {
                return {
                    url: `/${body.businessKey}`,
                    method: 'PUT',
                    body: body
                }
            },
            invalidatesTags: ['RealExpenses'],
        }),
        deleteRealExpense: builder.mutation({
            query: (businessKey) => {
                return {
                    url: `/${businessKey}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: ['RealExpenses'],
        }),
        fetchRealExpenses: builder.query<PageableDto<RealExpenseDto[]>, RealExpensesSearchRequest>({
            query: (request: RealExpensesSearchRequest) => {
                const {budgetBusinessKey, page, pageSize, dateFrom, dateTo, query} = request;
                return {
                    url: `/${budgetBusinessKey}?q=${query}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${pageSize}`,
                    method: 'GET'
                }
            },
            providesTags: ['RealExpenses'],
        }),
        fetchRealExpenseSummary: builder.query({
            query: (request: RealExpensesSummarySearchRequest) => {
                const {budgetBusinessKey, dateFrom, dateTo} = request;
                return {
                    url: `/${budgetBusinessKey}/summary?dateFrom=${dateFrom}&dateTo=${dateTo}`,
                    method: 'GET'
                }
            },
            providesTags: ['RealExpenses'],
        }),
        fetchRealExpensesByPlanned: builder.query({
            query: (businessKey) => {
                return {
                    url: `/by-planned-expense/${businessKey}`,
                    method: 'GET'
                }
            },
            providesTags: ['RealExpenses'],
        }),
    })
});

export const {
    useFetchRealExpensesQuery,
    useCreateRealExpenseMutation,
    useUpdateRealExpenseMutation,
    useDeleteRealExpenseMutation,
    useFetchRealExpenseSummaryQuery,
    useLazyFetchRealExpensesByPlannedQuery
} = realExpenseApi;
export {realExpenseApi};