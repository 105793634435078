import * as React from 'react';
import {useState} from 'react';
import {Alert, Checkbox, CircularProgress, FormControlLabel} from "@mui/material";
import PlannedExpenseList from "../../components/planned-expense-list/PlannedExpenseList";
import {defaultPage, defaultPageSize} from "../../constants/pagination";
import {useTranslation} from "react-i18next";
import {LoadingItem} from "../loading-item/LoadingItem";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import {Timeout} from "react-number-format/types/types";
import {getCookieTime} from "../../utils/cookie.utils";
import Cookies from "universal-cookie";
import {useFetchPlannedExpensesQuery} from "../../store";

interface Props {
    budgetBusinessKey: string,
    dateFrom: string,
    dateTo: string,
    expenseCategories: ExpenseCategoryDto[]
}

function PlannedExpenseWidget(props: Props) {
    const cookies = new Cookies();
    const {t} = useTranslation();
    const [page, setPage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [query, setQuery] = useState('');
    const [loadTimeoutId, setLoadTimeoutId] = useState<Timeout | undefined>(undefined);
    const [hidePaid, setHidePaid] = useState(
        cookies.get('hidePaid') === 'true'
    );
    const {
        budgetBusinessKey,
        dateFrom,
        dateTo,
        expenseCategories,
    } = props;
    const {
        data: pageable,
        isLoading: isPlannedExpensesLoading,
        error: plannedExpensesError,
        refetch: refetchPlannedExpenses
    } = useFetchPlannedExpensesQuery(
        {
            budgetBusinessKey,
            dateFrom,
            dateTo,
            q: query,
            hidePaid,
            page,
            pageSize,
        }); // todo: handle loading and error

    const delayLoadPlannedExpenses = () => {
        if (typeof loadTimeoutId === 'number') {
            clearTimeout(loadTimeoutId);
            setLoadTimeoutId(undefined);
        }

        const newId = setTimeout(() => {
            refetchPlannedExpenses().then();
        }, 1500);
        setLoadTimeoutId(newId);
    };

    const onChangeQuery = (event: any) => {
        let cleanedValue = event.target.value.replace(/[^\w\s.,]/g, "");
        cleanedValue = cleanedValue.replace(",", ".");
        setQuery(cleanedValue);
        delayLoadPlannedExpenses();
    };

    const handleCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setHidePaid(event.target.checked);
        const expires = getCookieTime();
        cookies.set('hidePaid', event.target.checked, {expires});
    };

    const onChangePage = async (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    }

    const onChangePageSize = async (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPage(0);
        setPageSize(newPageSize);
        const expires = getCookieTime();
        cookies.set('pageSize', newPageSize, {expires});
    }

    if (isPlannedExpensesLoading) {
        return <LoadingItem><CircularProgress/></LoadingItem>;
    }

    if (plannedExpensesError) {
        return <Alert severity="error">{t("Cannot load planned expenses!")}</Alert>;
    }

    if (!pageable && !isPlannedExpensesLoading) {
        return <Alert severity="info">No data!</Alert>;
    }

    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={hidePaid}
                        onChange={handleCheckboxChange}
                        color="primary"
                    />
                }
                label={t('Hide paid')}
            />
            <PlannedExpenseList
                pageable={pageable}
                query={query}
                page={page}
                pageSize={pageSize}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
                loadData={() => {
                    refetchPlannedExpenses()
                }}
                onChangeQuery={onChangeQuery}
            />
        </div>
    );
}

export default PlannedExpenseWidget;