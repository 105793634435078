import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/home/Home";
import Layout from "./Layout";
import Budget from "./pages/budget/Budget";
import SavingsGoal from "./pages/savings-goal/SavingsGoal";
import RealExpenseCreate from "./pages/real-expense-create/RealExpenseCreate";
import PageNotFound from "./pages/page-not-found/PageNotFound";
import User from "./pages/user/User";
import UserMessages from "./pages/user-messages/UserMessages";
import Planning from "./pages/planning/Planning";
import PlanningMonth from "./pages/planning-month/PlanningMonth";

function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="budget" element={<Budget/>}/>
                    <Route path="planning" element={<Planning/>}/>
                    <Route path="planning/:id" element={<PlanningMonth/>}/>
                    <Route path="create-expense" element={<RealExpenseCreate/>}/>
                    <Route path="savings-goals" element={<SavingsGoal/>}/>
                    <Route path="user" element={<User/>}/>
                    <Route path="user/messages" element={<UserMessages/>}/>
                    <Route path="*" element={<PageNotFound/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;
